<template>
  <b-card>

    <field-add-content field-group-id="-1" class="mt-2 pt-75"/>
  </b-card>
</template>

<script>
import {
  BTab, BTabs, BCard, BAlert, BLink, BSpinner
} from 'bootstrap-vue'
import FieldAddContent from './FieldAddContent.vue'

export default {
  components: {
    BTab,
    BTabs,
    BCard,
    BAlert,
    BLink,
    BSpinner,

    FieldAddContent,
  },
}
</script>
